/*
 *   File : Textarea.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea style
 *   Integrations :null
 *   Version : v1.0
 */

@import "./../config.scss";

.e_textarea {
  @extend %font14;
  background: $white;
  border: 0.5px solid $input_boder;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $dark_grey;
  padding: 12px 15px;
  border-radius: 8px;
  resize: none;
  &::placeholder {
    font-weight: 400;
    line-height: 18px;
    color: $dark_grey;
    opacity: 0.27;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $pale_cornflower_blue !important;
    padding: 0.375rem;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 16px;
  }
  
}

.e_textarea:focus {
  border: 0.5px solid $primary_color;
}
.e_input_error {
  border: 1px solid $error;
}
.e_error_msg {
  @extend %font12;
  color: $error !important;
}
